export const onSmall = () => {
  return window.innerWidth <= 640;
}


const SIZES = {
  'small': 640,
  'medium': 1024,
  'large': 9999999,
}

export const on = (size) => {
  switch(size){
    case 'small':
      return window.innerWidth < 640;
      break;
    case 'medium':
      return window.innerWidth > 640 && window.innerWidth < 1024;
      break;
    case 'large':
      return window.innerWidth > 1024  && window.innerWidth < 1200;
      break;
    case 'xlarge':
      return window.innerWidth >= 1200;
      break;
  }
}
