// Shift the add button to after the newly created field
document.addEventListener('turbolinks:load', function(e) {
  $(document).on('cocoon:after-insert', function(e) {
    const container = e.target;
    const button = container.querySelector('.add_fields');
    const newFields = container.nextElementSibling;
    if(container && button && newFields){
      container.appendChild(newFields);
      container.appendChild(button);
    }
  })
});