import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', () => {
  const clearForm = document.querySelector('.clear-form');
  if (clearForm) {
    clearForm.addEventListener('click', (e) => {
      e.preventDefault();
      const form = document.getElementById(e.target.getAttribute('data-form'));
      if(form){
        form.reset();
        Rails.fire(form, 'submit');
      }
    });
  }
})