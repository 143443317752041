// import "core-js/stable";
// import "regenerator-runtime/runtime";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")

require('foundation-sites');
const startFoundation = () => {
    $(function() { $(document).foundation(); });
}
$(document).on('turbolinks:load', function() {
    startFoundation();
});

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

require("trix")
require("@rails/actiontext")
require("app/imageGallery")
require("app/audioPlayer")
require("app/youtube")
require("app/triggerFileAttached");
require("app/direct_uploads");
require("app/close_flash");
require("app/homeHeroMargin");
require("app/stickyHeader");
require("app/nodownloadControl");
require("app/setSquare");
require("app/clearForm");
require("app/homeVideo");
// require("app/sizeFirstPara");
require("app/submitFormOnChange");
require("app/setDataIndexForMediaInputs");
require("app/shiftAddAssociationButtonAfterInsert");
require("app/setItemNumberInNestedForms");
require("app/UploadsController");
require("app/analytics.js.erb");
require("app/EmbedController");
require("app/stickyTrixToolbar");
require("app/gallery");
require("app/filterForm");
require("app/removeVideoForSmallScreens");


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import * as vis from '../app/visibility';
import filterForm from '../app/filterForm'
import submitFormOnChange from '../app/submitFormOnChange'

export {
    vis,
    filterForm,
    submitFormOnChange,
    startFoundation
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)