import * as vis from './visibility';
import Trix from "trix"
import Rails from "@rails/ujs"

class UploadsController {
  constructor(element) {
    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.addUploadButton = this.toolbar.querySelector('#add-upload-button');
    this.eventHandlersInstalled = false;
    this.popup;
    this.form;
    this.editButtons;
    this.submitButton;

    this.addUploadButton.addEventListener(
      'click', 
      this.initalizeAndShowPopup.bind(this)
    );
  }

  initalizeAndShowPopup(){
    this.initalizePopup();
    this.installPopupEventHandlers();
    this.showPopup();
  }

  initalizePopup() {
    this.popup = document.querySelector('#new-upload-popup-container');
    this.form = this.popup.querySelector('form');
    this.submitButton = this.popup.querySelector('button[type="submit"]');
    this.closeButton = this.popup.querySelector('button.close-button')
  }

  installPopupEventHandlers(){
    if(this.eventHandlersInstalled) return;
    
    this.submitButton.addEventListener(
      'click', 
      this.handleUploadFormSubmit.bind(this)
    );
    this.closeButton.addEventListener('click', this.reset.bind(this));
    this.eventHandlersInstalled = true;
  }

  showPopup() {
    vis.show(this.popup);
  }

  handleUploadFormSubmit(e){
    e.preventDefault();
    const form = new FormData(this.form);
    const data = new URLSearchParams(form).toString();
    Rails.ajax({
      url: this.form.action,
      type: 'post',
      data: data,
      error: this.handleError.bind(this),
      success: this.handleSuccess.bind(this)
    })
  }

  handleError(error) {
    console.log('handleError', error);
  }

  // Create an Attachment from the response and add to the editor
  handleSuccess(response) {
    let attachment = new Trix.Attachment(response)
    this.editor.insertAttachment(attachment)
    this.element.focus()
    this.reset();
  }

  reset() {
    vis.hide(this.popup);
    this.form.reset();
    // Remove Previews
    const previews = this.form.querySelector('#previews');
    if(previews) previews.innerHTML = '';
    // Clear hidden field with Upload Attachment Signed ID
    const attachment = this.form.querySelector('input[type="hidden"][name="upload[attachment]"]');
    attachment.value = '';
    // Hide all the feedback
    const validMessage = this.form.querySelector('.valid-message');
    vis.hide(validMessage);
    const invalidMessage = this.form.querySelector('.invalid-message');
    vis.hide(invalidMessage);
    const uploadError = this.form.querySelector('.upload-error');
    vis.hide(uploadError);
  }
}

document.addEventListener("trix-initialize", function(event) {
  new UploadsController(event.target)
})