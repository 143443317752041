document.addEventListener('trix-initialize', () => {
  const toolbar = document.querySelector('trix-toolbar');
  const editor = document.querySelector('trix-editor');
  if(toolbar && editor){
    const offset = document.querySelector('header').offsetHeight * 0.8;
    window.addEventListener('scroll', () => {
      const shouldStick = window.scrollY > (editor.offsetTop - offset)
      const pastEditor = window.scrollY > ((editor.offsetTop + editor.offsetHeight) - toolbar.offsetHeight)
      const aboveEditor = window.scrollY < (editor.offsetTop - offset)
      // if the user has scrolled past the top of the editor, 
      // add .stuck class to the toolbar 
      if(shouldStick){
        toolbar.classList.add('stuck');
        toolbar.style.top = offset + 'px';
      }
      // if the scroll is past the bottom of the editor, remove .stuck class
      if(pastEditor || aboveEditor) toolbar.classList.remove('stuck');
    })
  }
})
