import Amplitude from 'amplitudejs';
import * as vis from './visibility';

let currentIndex = 0;

document.addEventListener('turbolinks:load', () => {
  const audioPlayer = document.querySelector('#amplitude-audio-player');
  if (audioPlayer) {
    let tracks = JSON.parse(audioPlayer.getAttribute('data-tracks'));
    if(tracks){
      Amplitude.init({ 
        songs: tracks,
        callbacks: {
          song_change: onSongChange
        }
      });
      setupPlayerButtonListeners();
    }
  }
})

const setupPlayerButtonListeners = () => {
  const songContainers = document.querySelectorAll('.amplitude-song-container');
  songContainers.forEach((songContainer) => {
    const songIndex = songContainer.getAttribute('data-amplitude-song-index');
    const playButton = songContainer.querySelector('.amplitude-play');
    const progressTrack = document.querySelector(`#progress-track-${songIndex}`);

    playButton.addEventListener('click', () => {
      currentIndex = parseInt(songIndex);
      console.log(currentIndex);
      showAllPlayAndTimeContainer();
      hideAllProgressTrackContainers();
      vis.hide(playButton.parentElement.parentElement);
      vis.show(progressTrack);
    });
  })
}

const hideAllProgressTrackContainers = () => {
  const progressContainers = document.querySelectorAll('.progress-track');
  progressContainers.forEach((progressContainer) => {
    vis.hide(progressContainer);
  });
}

const showAllPlayAndTimeContainer = () => {
  const containers = document.querySelectorAll('.control-container');
  containers.forEach((container) => {
    vis.show(container.parentElement);
  });
}

const onSongChange = (e) => {
  currentIndex += 1;
  showAllPlayAndTimeContainer();
  hideAllProgressTrackContainers();
  // hide new song's controls
  const currentControlContainer = document.querySelector(`.control-container[data-amplitude-song-index="${currentIndex}"]`);
  if(currentControlContainer) vis.hide(currentControlContainer.parentElement);
  // show new progress
  const nextTrack = document.querySelector(`#progress-track-${currentIndex}`);
  console.log(currentIndex);
  if(nextTrack) vis.show(nextTrack);
}