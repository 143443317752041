import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', () => {
  const imageGallery = document.querySelector('#image-gallery');
  if (imageGallery) {
    const images = imageGallery.querySelectorAll('.gallery-image');
    for(let i = 0; i < images.length; i++) {
      const image = images[i];
      image.addEventListener('click', () => {
        const id = image.getAttribute('data-id');
        const assoc = image.getAttribute('data-assoc');
        const assocId = image.getAttribute('data-assoc-id');
        console.log(assoc)
        console.log(assocId)
        Rails.ajax({
          url: `/${assoc}/${assocId}/images/?id=${id}`,
          type: "get",
          data: "",
          success: function(data) {},
          error: function(data) {}
        })
      })
    }
  };
});