import React from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from 'video-react';
import "video-react/styles/scss/video-react";

class Video extends React.Component {
  constructor(props){
    super(props);
    this.player = React.createRef();
  }

  render () {
    let { src, item, ...rest } = this.props;
    if(!src) src = item["video"]["src"];

    return (
      <Player
        ref={this.player}
        src={src}
        preload={'auto'}
        {...rest}
      >
        <BigPlayButton position="center" />
      </Player>
    );
  }
}

Video.propTypes = {
  item: PropTypes.object
};

export default Video;
