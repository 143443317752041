import * as measure from './measure';

// Remove the video from DOM on small screens
// THIS IS A HACK TO STOP VIDEO OPENING ON MOBILE WHEN MENU CLICKED
document.addEventListener('turbolinks:load', () => {
  if(document.querySelector('body.home.index')){
    const video = document.querySelector('video#home-video');
    if(measure.onSmall() && video){
      video.remove();
    }
  }
})