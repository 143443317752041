import * as vis from './visibility';
import Trix from "trix"
import Rails from "@rails/ujs"

class EmbedController {
  constructor(element) {
    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.addEmbedButton = this.toolbar.querySelector('#add-embed-button');
    this.popup;
    this.form;
    this.editButtons;
    this.submitButton;

    this.addEmbedButton.addEventListener(
      'click', 
      this.initalizeAndShowPopup.bind(this)
    );
  }

  initalizeAndShowPopup(){
    this.initalizePopup();
    this.installPopupEventHandlers();
    this.showPopup();
  }

  initalizePopup() {
    this.popup = document.querySelector('#new-embed-popup-container');
    this.form = this.popup.querySelector('form');
    this.submitButton = this.popup.querySelector('button[type="submit"]');
    this.closeButton = this.popup.querySelector('button.close-button')
  }

  installPopupEventHandlers(){
    this.submitButton.addEventListener(
      'click', 
      this.handleUploadFormSubmit.bind(this)
    );
    this.closeButton.addEventListener('click', this.reset.bind(this));
  }

  showPopup() {
    vis.show(this.popup);
  }

  handleUploadFormSubmit(e){
    e.preventDefault();
    const form = new FormData(this.form);
    const data = new URLSearchParams(form).toString();
    Rails.ajax({
      url: this.form.action,
      type: 'post',
      data: data,
      error: this.handleError.bind(this),
      success: this.handleSuccess.bind(this)
    })
  }

  handleError(error) {
    console.log('handleError', error);
  }

  // Create an Attachment from the response and add to the editor
  handleSuccess(response) {
    let attachment = new Trix.Attachment(response)
    this.editor.insertAttachment(attachment)
    this.element.focus()
    this.reset();
  }

  reset() {
    vis.hide(this.popup);
    this.form.reset();
  }

}

document.addEventListener("trix-initialize", function(event) {
  new EmbedController(event.target)
})