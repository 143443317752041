import React from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from 'video-react';
import "video-react/styles/scss/video-react";

const PREVIEW_DURATION = 30.0

class OpaVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewMessage: false
    };
  }

  componentDidMount() {
    if(this.props.previewOnly){
      this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }
  }

  handleStateChange(player){
    const { currentTime } = player;
    if(currentTime > PREVIEW_DURATION){
      this.player.pause();
      this.player.seek(0);
      this.setState({ previewMessage: true })
    }
  }

  render () {
    return (
      <div className={`relative ${this.props.containerClass}`}>
        <Player
          ref={player => {
            this.player = player;
          }}
          src={this.props.item.video}
          poster={this.props.item.image}
          preload={'auto'}
          playsInline
        >
          {!this.state.previewMessage &&
            <BigPlayButton position="center" />
          }
        </Player>

        {this.state.previewMessage &&
          <div className="video-preview-message">
            <h3>
              become a member to access this and all other tutorials on OPA!
            </h3>
            <a href="/purchases" className='opa-button block'>
              <label className='white'>become a member</label>
              <img
                src={this.props.images.button_white}
                alt={'become a member'}
              />
            </a>
          </div>
        }

      </div>

    );
  }
}

OpaVideo.propTypes = {
  item: PropTypes.object
};
export default OpaVideo
