export const hide = (element) => {
  if(!element.classList.contains('hidden')){
    element.classList.add('hidden');
  }
}

export const show = (element) => {
  if(element.classList.contains('hidden')){
    element.classList.remove('hidden')
  }
}

export const hideById = (id) => {
  const element = document.getElementById(id);
  if(element) hide(element);
}

export const showById = (id) => {
  const element = document.getElementById(id);
  if(element) show(element);
}

export const toggle = (element) => {
  if(element.classList.contains('hidden')){
    show(element);
  } else {
    hide(element);
  }
}

export const toggleById = (id) => {
  const element = document.getElementById(id);
  if(element.classList.contains('hidden')){
    show(element);
  } else {
    hide(element);
  }
}


export const fadeOut = (element) => {
  if(!element.classList.contains('trans')){
    element.classList.add('trans');
  }
}

export const fadeIn = (element) => {
  if(element.classList.contains('trans')){
    element.classList.remove('trans')
  }
}

export const fadeOutById = (id) => {
  const element = document.getElementById(id);
  if(element) fadeOut(element);
}

export const fadeInById = (id) => {
  const element = document.getElementById(id);
  if(element) fadeIn(element);
}

export const fadeInAllByClass = (klass) => {
  const items = document.querySelectorAll(`.${klass}`);
  for(let i = 0; i < items.length; i++){
    fadeIn(items[i]);
  }
}

export const fadeOutAllByClass = (klass) => {
  const items = document.querySelectorAll(`.${klass}`);
  for(let i = 0; i < items.length; i++){
    fadeOut(items[i]);
  }
}


export const removeById = (id) => {
  var item = document.getElementById(id);
  if (item) item.parentNode.removeChild(item);
}

export const removeAllById = (id) => {
  const items = document.querySelectorAll(`#${id}`);
  for(let i = 0; i < items.length; i++){
    items[i].parentNode.removeChild(items[i]);
  }
}

export const removeAllByClass = (klass) => {
  const items = document.querySelectorAll(`.${klass}`);
  for(let i = 0; i < items.length; i++){
    items[i].parentNode.removeChild(items[i]);
  }
}

export const hideBySelector = (selector) => {
  const element = document.querySelector(selector);
  if(element) hide(element);
}

export const showBySelector = (selector) => {
  const element = document.querySelector(selector);
  if(element) show(element);
}
