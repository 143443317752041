const square = () => {
  const squares = document.querySelectorAll('.square')
  if(squares){
    for(let i = 0; i < squares.length; i++){
      const square = squares[i];
      square.style.height = square.offsetWidth + 'px';
    }
  }
}

const setSquare = () => {
  let loaded = false;
  square()
  if(!loaded) window.addEventListener('resize', () => square());
  loaded = true;
}

document.addEventListener('turbolinks:load', setSquare);

export default setSquare;


const setSquareOnLoad = () => {
  let loaded = false;
  const squares = document.querySelectorAll('.set-square-on-load')
  for(let i = 0; i < squares.length; i++){
    squares[i].onload = () => squareElement(squares[i]);
    if(!loaded) window.addEventListener(
      'resize',
      () => squareElement(squares[i])
    );
    loaded = true;
  }
}


document.addEventListener('turbolinks:load', setSquareOnLoad);

const squareElement = (el) => {
  console.log(el)
  el.style.height = el.offsetWidth + 'px';
}
