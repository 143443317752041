import Rails from '@rails/ujs';

const filterForm = () => {
  const forms = document.querySelectorAll('.filter-form');
  
  for (let i = 0; i < forms.length; i++) {
    const form = forms[i]; 

    const categorySelector = form.querySelector('select[name="category"]');
    if(categorySelector){
      categorySelector.addEventListener('change', setCategories);
    }

    const resetButton = form.querySelector('button.reset-button');
    if(resetButton){
      resetButton.addEventListener('click', resetForm);
    }

    const tags = form.querySelectorAll('.tag');
    for (let j = 0; j < tags.length; j++) {
      const tag = tags[j];
      tag.addEventListener('click', removeTag);
    }

    const searchButton = form.querySelector('button.search-button');
    if(searchButton){
      searchButton.addEventListener('click', search);
    }
  }
}

const setCategories = (e) => {
  // get value of target  
  const value = e.target.value;
  // get categoryIds field
  const categoryIds = getCategoryIds(getForm(e.target));
  // if value is not in categoryIds value, add value to categoryIds value
  if(categoryIds.value.indexOf(value) === -1){
    categoryIds.value += value + ',';
  }
  submitForm(getForm(e.target));
}

const resetForm = (e) => {
  // get the form
  const form = getForm(e.target);
  if(form){
    form.reset();
    clearCategoryIds(form);
    submitForm(form);
  }
}

const removeTag = (e) => {
  let tag = e.target;
  // remove e.target element from parentElement
  if(!e.target.classList.contains('tag')){
    tag = e.target.parentElement;
  }
  // remove the value from categoryIds
  const categoryIds = getCategoryIds(getForm(tag));
  const value = tag.getAttribute('data-value');
  categoryIds.value = categoryIds.value.replace(value + ',', '');
  // delete the element
  tag.parentElement.removeChild(tag);
  setTimeout(
    () => submitForm(getForm(tag)), 250
  )
  // submitForm(getForm(e.target));
}

// TODO
const search = (e) => {
  // not sure if we do this here but the search term needs to be added to the list of tags and the search cleared so we can do multiple search terms
}

const getForm = (element) => {
  const id = element.getAttribute('data-form');
  return document.getElementById(id);
}

const getCategoryIds = (form) => {
  return form.querySelector('#category_ids');
}

const clearCategoryIds = (form) => {
  const categoryIds = getCategoryIds(form);
  if(categoryIds) categoryIds.value = '';
}

const submitForm = (form) => {
  Rails.fire(form, 'submit');
}

// call filterForm on turbolinks:load
document.addEventListener('turbolinks:load', filterForm);

export default filterForm;