import * as vis from './visibility';

document.addEventListener('turbolinks:load', () => {

  const galleryContainers = document.querySelectorAll('.gallery-container');

  for(let i = 0; i < galleryContainers.length; i++) {
    const galleryContainer = galleryContainers[i];
    const scroller = galleryContainer.querySelector('.scroller');

    if(!scroller) continue;

    const leftGr = galleryContainer.querySelector('.gallery-gradient.left');
    const rightGr = galleryContainer.querySelector('.gallery-gradient.right');

    if(!leftGr || !rightGr) continue;

    // show right scroll button if scroller is wider than parent
    const scrollerOverflows = scroller.scrollWidth > galleryContainer.clientWidth;
    if(scrollerOverflows) vis.fadeIn(rightGr)

    // toggle visibility of scroll buttons when scroller scrolled
    scroller.addEventListener('scroll', () => {
      if(scroller.scrollLeft > 0) vis.fadeIn(leftGr);
      else vis.fadeOut(leftGr);

      const fullyScrolledRight = scroller.scrollLeft >= ((scroller.scrollWidth - scroller.clientWidth) - 2);
      if(fullyScrolledRight) vis.fadeOut(rightGr);
      else vis.fadeIn(rightGr);
    })

    // add click listener to leftGr
    leftGr.addEventListener('click', () => {
      if(scroller.scrollLeft > 0) {
        scroller.scrollTo({
          top: 0,
          left: scroller.scrollLeft - 300,
          behavior: 'smooth'
        });
      }
    })

    // add click listener to rightGr
    rightGr.addEventListener('click', () => {
      scroller.scrollTo({
        top: 0,
        left: scroller.scrollLeft + 300,
        behavior: 'smooth'
      });
    });
  }
})